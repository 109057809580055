.header{
  overflow-x:hidden;
} 
.header__logo-box { 
  position:fixed;
  z-index:3;
  top:1.5em;
  left:1.5em;
  @media screen and (max-height: 600px) {
    display:none;
  }
  img { 
    width: 200px; 
    @include mobile-xs {
      width: 120px; 
    }
  }
}




.header__menu-mobile {
  @include reset;
}

.header__menu-mobile {
  position:fixed;
  right:30px;
  top:30px;
  background-color:rgba(#000,.2);
  padding:6px;
  cursor:pointer;
  border-radius:8px;
  z-index:100 !important;
  border:1px solid #444;
  z-index:4;
  @include mobile {
    // right:10px;
    // top:15px;
  }
  .item {
    height:8px;
    width:45px;
    margin-bottom: 5px;
    display:block;
    border:1px solid #000;
    box-sizing:border-box;
    background-color:#FFE507;
    transition:.3s all;
    @include mobile {
     width:35px;
     height:5px;
   }
   &--1 {

   }
   &--2 {

   }
   &--3 {
    margin-bottom: 0;
  }
}
&.is-active {

  .item {
    &--1 {
      transform:translateY(10px) rotate(45deg);
      @include mobile {
        transform:translateY(9px) rotate(45deg);
      }
    }
    &--2 {
      opacity: 0;
      transform:translateX(100%);
      @include mobile {

      }
    }
    &--3 {
      transform:translateY(-15px) rotate(-45deg);
      @include mobile {
        transform:translateY(-10px) rotate(-45deg);

      }
    }
  }
}
}

.header__menu {
  padding:90px 0 30px;
  @include reset_list;
  a {
    text-decoration: none;
    color:#FFF;
  }
  li {
    text-align:center;
    a {
      display:block;
      padding:15px 20px;
      border-radius:7px;
      background-color:#FFF;
      transition:.05s all;
      border:2px solid #000;
      color:#000;
      &:hover {
        text-indent: 10px;
        opacity:.9;
        background-color:darken(#FFF,5);
      }
    }
  }
}
.header__layer {
  @extend .relative;
}

.header__layer.no-active-menu {
  // @extend .e-slide-out-right;
}
.header__layer {

  opacity:0;
  transform:translateX(100vw);
  transition:.5s transform;

  #body.is-active-menu & {
    opacity:1;
    transform:translateX(0vw);
  }

  &.no-active {
   @extend .e-slide-out-right;
   opacity:0;
 }

 &.is-active ,
 &.no-active 
 {
  height:100%;
  max-height:100%;
  width:90vw;
  max-width: 400px;
  position:fixed;
  top:0;
  right:0;
  padding:0 20px;
  overflow:auto;
  white-space: nowrap;
  background-color:lighten(rgba(#ededed,.98) , 0);
  overflow:auto;
  z-index:99;
  box-shadow: -10px 0px 20px rgba(#ededed,.2);
    // animation: e-slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);

    .header__menu {
      display:block;
      margin:0 auto;
      max-width:90%;
      @include mobile-xs {
        max-width: 100%;
      }

      ul {
        > li {
          width:100%;
          margin-bottom:1.2em;
          &:hover ,
          &[data-page="current"]
          {

            &::before {
              display:none;
            }
          }
          &[data-page="current"] {
            & > a {
              &::before {
                content:"";
                height:3px;
                width:60%;
                position:absolute;
                bottom:0;
                background-color:$blue;
              }
            }
          }
          a {
          }
          & > ul {
            li {
              margin-bottom:0;
            }
          }
        }
        li.is-open {
          ul {
            animation: e-fade-in .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            position:initial;
          }
        }
      }
    }
  }
}




