.c-app-fm {
  position:absolute;
  z-index:4;
  bottom:0;
  left:0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px 20px 60px;
  z-index:1;
  .cover { 
    text-align: center; 
    width: 200px; 
    display:inline-block;
    background-color: #FFF;
    padding:10px;
    border:10px solid #CCC;
    margin-right:40px;
    margin-bottom: 20px;
    @include tablet {
      width: 150px; 
    }
    img { 
      width: 100%; 
    } 
  }
  .controls{ 
    display:inline-block; 
    button { 
      display: block;
    }
    h2 {
      @include tablet {
        font-size: 1.5em;
      }

    }
    h3 { 
       @include tablet {
        font-size: 1.3em;
        
      }
    }
    .play-button , 
    .stop-button 
    { 
      max-height:64px; 
      cursor: pointer; 
      margin-left: 0;
      margin-right:0;
       @include tablet {
        max-height:50px; 

       }
    }
    .stop-button { 
      display:none; 
    }
  }
}