.c-source-rss {
  .source-rss-logo {  
    position: absolute; 
    display:flex;
    flex-direction: column;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;  
    background-color: white;
    padding:  16px;
    width:  220px;
    height:100%;
    @media (max-width: 600px) {
      width:  160px;
    }
    @include mobile-xs {
      width:  100px;
    }
    figure {
      margin:0 auto;
    }
    img { 
      margin:0 auto;
      display:block;
    } 
  }
}
