// GENERAL THEME EXTRAS
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}

#marquee {
  overflow: hidden;
  margin: 0;
  bottom:0;
  padding: 7px 0;
  font-size: 1.5em;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #A41D23;
  color: #ffffff;
  @include mobile {
    font-size: 1.2em;
  }
  @include mobile-xs {
    font-size: 1em;
  }
}

.e-content {
  max-width: 1200px;
  padding-top:100px;
  padding-bottom:100px;
  a {
    border-bottom:1px dotted #888;
    @extend .dim;
  }
  header {
    margin-bottom:40px;
  }
}

.e-content-page {
  // position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px 20px 60px;
  z-index:1;
}












