// Measures
$mobile     : 767px; //768px = 48em x 16px (-1px)
$container  : 1248px;

// Grid px  REV
$mobile_px      : 500px;
$tablet_px      : 800px;
$desktop_px     : 1024px; // 64em
// $grid-container : 1400px;
// $grid-super     : 1920px;

$grid-mobile-theme : 1000px;

$path : "../../";

// breakpoint 
$breakpoint-not-small: 'screen and (min-width: 48em)' !default;
$breakpoint-medium: 'screen and (min-width: 48em) and (max-width: 64em)' !default;
$breakpoint-large: 'screen and (min-width: 64em)' !default;

// Fonts
$Cabin      : 'Cabin', sans-serif;
$Raleway    : 'Raleway', sans-serif;
$Multi      : 'Muli', sans-serif;
$Roboto     : 'Roboto', sans-serif;
$Montserrat : 'Montserrat', sans-serif;
$BebasNeue  : 'BebasNeue';
$SansPro    : 'Source Sans Pro', sans-serif;
$Assistant  : 'Assistant', sans-serif;
$Quicksand  : 'Quicksand', sans-serif;
$OpenSansCodensed  : 'Open Sans Condensed', sans-serif;
$YanoneKaffeesatz  : 'Yanone Kaffeesatz', sans-serif;
$DINCondensed-Bold :  DINCondensed-Bold;  // Font extern || No GoogleFonts
$CabinCondensed    : 'Cabin Condensed', sans-serif;
$Poppins           : 'Poppins', sans-serif;
$Oswald            : 'Oswald', sans-serif;
$Bordonaro         :  Bordonaro;
$Arimo             : 'Arimo', sans-serif;
$Muli              : 'Muli', sans-serif;
$ShadowsIntoLight  : 'Shadows Into Light', cursive;
$SairaCondensed    : 'Saira Condensed', sans-serif;
$DroidSerif : 'Droid Serif',sans-serif;
$Cabin : 'Cabin',sans-serif;

// Colors
$branding_green--light: #CFE5D0;

$branding_green_txt   : #2F9D54;
$branding_blue_txt    : #08344C;

$branding_yellow      : #DBB30E;
$branding_pink        : #B83178;

$branding_green_2     : #2DA836;


$branding_red         : #DE0713;  
$branding_red_2       : #8e000d;
$branding_lilac       : #7A4871;
$branding_blue_2      : #17495D;

$branding_blue-green  : #16AEA6;
$branding_green_3     : #8DA826;
$branding_green_4     : #5EBAA4;

$branding_orange        : #FFA34C;
$branding_orange_2      : #F08019;
$branding_orange--light : #F1A24D;

$branding_beige       : #E9E5DA;
$branding_green       : #d1d300; 
$branding_brown       : #996633;

// RUN COLORS THEME     
$branding_blue        : #22CBFF;
$branding_black       : #1D1D1B;
// END COLORS THEME

$branding_black-3     : #222222;
$branding_black-2     : #333333;

// Experimental
// background-blend-mode: multiply;  Images TEST >>> https://pixabay.com/

$branding_blue2       : #0092D8;
$branding_white       : #F3F3F3;
$branding_gray        : #666666;
$branding_gray--darken: #444444;
$branding_gray--light : #999999;
$branding_gray--light2: #E9E9E9; // Footer
$branding_gray--light3: #E3E3E3; // lines menu
$branding_gray--text  : #585857;

// Fonts Theme
$font-thin-theme       : 100;
$font-extralight-theme : 200;
$font-light-theme      : 300;
$font-regular-theme    : 400;
$font-medium-theme     : 500;
$font-semibold-theme   : 600;
$font-bold-theme       : 700;
$font-extrabold-theme  : 800;
$font-black-theme      : 900;
// END Fonts Theme
