#body.is-active-menu #js-body__header__menu-mobile {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(#111, 0.7);
    z-index: 1;
    cursor: pointer;
      z-index:4;
}



#body.is-active-menu {

  // @include mobile {
    overflow-y:hidden;
    #main {
      min-height: 100vh;
    }
    #main , #footer
    {
      filter: blur(4px);
      overflow:hidden;
    }
    #header {
      z-index:99;
    }
  // }
}